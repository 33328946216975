import React from "react";

const Description = ({ sale, onNextClick }) => {
  const banner = sale.image.url;

  return (
    <div className="w-[359px] md:w-[576px]">
      <img
        src={banner}
        alt="banner"
        className="rounded-t-[8px] md:rounded-tr-[0px] md:w-[576px] md:rounded-tl-[8px]"
      />
      <div className="bg-white py-[30px] px-[27px] font-['Montserrat'] leading-[21px] text-[14px] rounded-b-[0px] md:rounded-br-0 md:rounded-bl-[8px]">
        <h2 className="font-['Eveleth'] text-[24px] leading-[24px] mb-[12px]">
          {sale.title}
        </h2>
        <div
          dangerouslySetInnerHTML={{ __html: sale.description }}
          className="mb-4"
        />
        <label for="terms">
          <input
            id="terms"
            type="checkbox"
            className="mr-2 rounded-sm border-none cursor-pointer"
          />
          Agree to the{" "}
          <a href="#0" className="focus:outline-none text-[#265CA6]">
            terms and conditions
          </a>
        </label>
        <div className="visible md:invisible flex items-center justify-center mt-4">
          <button
            onClick={onNextClick}
            className="bg-[#265CA6] rounded-[50px] w-[280px] h-[50px] my-3 text-white text-[14px] leading-[17px] font-bold cursor-pointer"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Description;
