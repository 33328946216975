import React, { useState } from "react";
import Description from "../components/description/Description";
import Form from "../components/form/Form";

const OrderPage = ({ sale }) => {
  const [formShow, setFormShow] = useState(false);

  return (
    <div>
      {/* mobile */}
      <div className="visible md:invisible h-max md:h-0 flex items-center justify-center">
        {formShow ? (
          <Form sale={sale} />
        ) : (
          <Description sale={sale} onNextClick={() => setFormShow(!formShow)} />
        )}
      </div>
      {/* desktop */}
      <div className="invisible md:visible h-0 md:h-max flex justify-center pt-[53px] bg-white">
        <Description sale={sale} onNextClick={formShow} />
        <Form sale={sale} />
      </div>
    </div>
  );
};

export default OrderPage;
