import React from "react";
import banner_noraffle from "../assets/images/banner_noraffle.png";

const NoRaffle = () => {
  return (
    <div className="h-full w-[320px] md:w-[700px]">
      <div className="h-[20px] md:h-[100px]">
        <img
          src={banner_noraffle}
          alt=""
          className="md:w-full rounded-t-[16px]"
        />
      </div>
      <div className="bg-[#054279] grid justify-center text-white items-center pt-[100px] rounded-b-[16px] h-[370px]">
        <div className="my-5 mx-[20px] md:mx-0 mb-[100px] md:mb-[150px] md:pt-[80px]">
          <h1 className="eveleth_font text-[34px] leading-[38px] md:text-[38px] md:leading-[32px] text-center">
            THERES NO RAFFLE YET
          </h1>
          <h3 className="font-['Montserrat'] text-center text-[18px] leading-[21px] md:text-[20px] md:leading-[22px] pt-[20px]">
            Please stay tune for our new raffle will be inform in here
          </h3>
        </div>
      </div>
    </div>
  );
};

export default NoRaffle;
