import React from "react";
import phang_logo from "../../assets/images/phang_logo.svg";

const Header = () => {
  return (
    <div className="flex justify-center">
      <img
        src={phang_logo}
        alt="logo"
        className="w-[250px] cursor-pointer md:w-[302px]"
        onClick={() => window.location.reload()}
      />
    </div>
  );
};

export default Header;
