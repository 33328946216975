import React from "react";
import Instagram from "../../assets/images/phangkey_instagram.svg";

const Footer = () => {
  return (
    <div className="md:mt-2">
      <div className="grid justify-center pt-[30px] md:pt-[53px]">
        <a
          href="https://instagram.com/phangkey"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="Instagram Logo" />
        </a>
      </div>
      <div className="grid justify-center pt-[10px] text-[14px] leading-[17px] font-['Montserrat']">
        © 2022 All Right Reserved. PhangKey
      </div>
    </div>
  );
};

export default Footer;
