import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import img_background from "../assets/images/background.svg";
import Header from "../components/header/Header";
import CountdownPage from "../pages/CountdownPage";
import OrderPage from "../pages/OrderPage";
import NoRaffle from "../pages/NoRaffle";
import Footer from "../components/footer/Footer";
// import { data } from "../data";

const Home = () => {
  const [sale, setSale] = useState();

  useEffect(() => {
    // Fetch sale

    const getSale = async () => {
      try {
        const response = await axios({
          baseURL: process.env.REACT_APP_BASE_URL,
          url: "/api/v1/sales",
          method: "get",
        });
        const saleData = await response.data;
        console.log(saleData);
        if (saleData.sale) {
          setSale(saleData);
        }
      } catch ({ response }) {
        console.log(response.data.errors);
      }
    };
    getSale();

    // setSale(data[0]);
    // console.log(sale);
  }, []);

  return (
    <>
      <Helmet>
        <title>{sale && "Phangkey | " + sale.title}</title>
      </Helmet>
      <div
        className={
          !sale ? "w-screen h-screen overflow-auto" : "w-screen overflow-auto"
        }
      >
        <div
          style={{
            backgroundImage: `url(${img_background})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="py-[40px] flex flex-col justify-between md:px-[172px] h-full w-full overflow-hidden"
        >
          <Header />
          <div className="flex justify-center pt-[40px]">
            {sale && (
              <CountdownPage
                sale={sale}
                complete={
                  <>
                    <OrderPage sale={sale} />
                  </>
                }
              />
            )}
            {!sale && <NoRaffle />}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
