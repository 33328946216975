import React from "react";
import { BiErrorCircle } from "react-icons/bi";

const OrderPlacedModal = ({ onClose, data }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
      <div className="grid justify-center w-[300px] py-[30px] md:py-[45px] md:w-[390px] bg-white rounded-[20px]">
        <div className="grid justify-center mb-2">
          <BiErrorCircle className="text-[#FF3D5F] text-[100px]" />
        </div>
        <h2 className="text-center text-[24px] leading-[33px] font-[950px] text-[#FF3D5F] font-['Eveleth']">
          ERROR
        </h2>
        <p className="text-center font-['Montserrat'] text-[14px] leading-[17px] text-[#FF3D5F] font-bold pt-[5px] pb-[30px]">
          {data}
        </p>
        <div className="flex items-center justify-center">
          <button
            className="border-2 text-center bg-[#FF3D5F] w-[150px] rounded-[50px] py-[16px] cursor-pointer text-white font-['Eveleth']"
            onClick={onClose}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderPlacedModal;
