import React from "react";
import Countdown from "react-countdown";

const CountdownPage = ({ complete, sale }) => {
  const startDate = Date.parse(sale.start_date);
  // const dateString = new Date(startDate).toString();
  const banner_url = sale.image.url;

  // const startDate = useRef(Date.now());
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    console.log(completed);
    if (completed) {
      // Render a completed state
      return <div>{complete}</div>;
    } else {
      // Render a countdown
      return (
        <div className="h-screen -mb-[260px] md:-mb-[300px]">
          <div className="w-[359px] md:w-full">
            <div className="h-[20px] md:h-[100px]">
              <img
                src={banner_url}
                alt=""
                className="w-full rounded-t-[16px] h-[200px] md:h-[240px] object-cover"
              />
            </div>
            <div className="bg-white grid justify-center items-center rounded-b-[8px] pt-[180px] md:pt-[120px]">
              <div className="my-5">
                <h1 className="font-['Eveleth'] text-[24px] leading-[38px] md:text-[36px] md:leading-[76px] md:mx-6 text-[#054279] text-center">
                  The Form will be open
                  <br />
                </h1>
                {/* <h3 className="font-['Montserrat'] text-center text-[18px] leading-[21px] md:text-[29px] md:leading-[35px] text-[#054279] pt-[20px]">
                {dateString}
              </h3> */}
              </div>
              <div className="flex font-['Montserrat'] justify-center mb-5 pb-[20px]">
                <div className="grid justify-center pr-4">
                  <div className="py-[24px] px-[10px] bg-[#FFF9F5]">
                    <span className="text-center text-[40px] leading-[49px] text-[#D02E47] font-bold">
                      {days}
                    </span>
                  </div>
                  <p className="text-center text-[16px] leading-[20px] text-[#054279] font-[600px]">
                    Days
                  </p>
                </div>
                <div className="grid justify-center pr-4">
                  <div className="py-[24px] px-[10px] bg-[#FFF9F5]">
                    <span className="text-center text-[40px] leading-[49px] text-[#D02E47] font-bold">
                      {hours}
                    </span>
                  </div>
                  <p className="text-center text-[16px] leading-[20px] text-[#054279] font-[600px]">
                    Hours
                  </p>
                </div>
                <div className="grid justify-center pr-4">
                  <div className="py-[24px] px-[10px] bg-[#FFF9F5]">
                    <span className="text-center text-[40px] leading-[49px] text-[#D02E47] font-bold">
                      {minutes}
                    </span>
                  </div>
                  <p className="text-center text-[16px] leading-[20px] text-[#054279] font-[600px]">
                    Mins
                  </p>
                </div>
                <div className="grid justify-center pr-4">
                  <div className="py-[24px] px-[10px] bg-[#FFF9F5]">
                    <span className="text-center text-[40px] leading-[49px] text-[#D02E47] font-bold">
                      {seconds}
                    </span>
                  </div>
                  <p className="text-center text-[16px] leading-[20px] text-[#054279] font-[600px]">
                    Secs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <Countdown date={startDate} renderer={renderer} />
    </div>
  );
};

export default CountdownPage;
