import React, { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import axios from "axios";
import OrderPlacedModal from "../modal/OrderPlacedModal";
import ErrorModal from "../modal/ErrorModal";
import GoToTop from "../../hooks/goToTop";

const Form = ({ sale, formSubmit }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      want_to_buy: [],
    },
  });

  const [internationalBuyer, setInternationalBuyer] = useState(true);
  const [internationalAddress, setinternationalAddress] = useState(false);
  const [userCountry, setUserCountry] = useState("us");
  const [showModal, setShowModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const setIndonesiaBuyer = useCallback(() => {
    setInternationalBuyer(false);
    setValue("shipping_address", "Tokopedia");
  }, [setValue]);

  useEffect(() => {
    const getGeoInfo = async () => {
      try {
        const response = await axios({
          url: "https://ipapi.co/json",
        });

        let data = response.data;
        setUserCountry(data.country.toLowerCase());

        if (data.country === "ID") {
          setIndonesiaBuyer();
        }
      } catch (e) {
        setIndonesiaBuyer();
      }
    };
    getGeoInfo();
  }, [setIndonesiaBuyer]);

  function onSubmit(data) {
    const participant_data = {
      user: {
        email: data.email,
        name: data.name,
        phone: data.phone,
        address: data.shipping_address,
        country: userCountry,
      },
      participant: {
        sale: sale.sale,
        reference: "",
        notes: data.notes,
        want_to_buy: data.want_to_buy,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/participants`,
        participant_data
      )
      .then(function (response) {
        reset();
        setShowModal(true);
      })
      .catch(({ response }) => {
        setErrorModal(true);
      });
  }

  return (
    <>
      <div className="w-[359px] bg-[#004385] text-white font-['Montserrat'] text-[14px] pb-6 rounded-[8px] md:rounded-[0px] md:rounded-tr-[8px] md:rounded-br-[8px]">
        <form onSubmit={handleSubmit(onSubmit)} className="px-[32px] pt-[28px]">
          <h2 className="font-['Eveleth'] text-[21px] leading-[21px] pb-[28px]">
            JOIN RAFFLE
          </h2>
          <label>Name</label>
          <br />
          <input
            {...register("name", {
              required: "Name is required",
            })}
            className="bg-white/10 px-[13px] focus:outline-none rounded-[10px] border-[1px] border-white w-[290px] h-[40px] my-3"
          />
          {errors.name && (
            <div className="font-['Montserrat'] flex items-center justify-end text-[12px] leading-[15px]">
              <div className="bg-[#FF3D5F] py-[5px] px-[20px] rounded-l-full rounded-br-full -mt-2 mr-2">
                {errors.name.message}
              </div>
            </div>
          )}
          <br />
          <label>Email</label>
          <br />
          <input
            {...register("email", {
              required: "Email is required",
            })}
            className="bg-white/10 px-[13px] focus:outline-none rounded-[10px] border-[1px] border-white w-[290px] h-[40px] my-3"
          />

          {errors.email && (
            <div className="font-['Montserrat'] flex items-center justify-end text-[12px] leading-[15px]">
              <div className="bg-[#FF3D5F] py-[5px] px-[20px] rounded-l-full rounded-br-full -mt-2 mr-2">
                {errors.email.message}
              </div>
            </div>
          )}
          <br />
          <label>Choose keycaps do you want to win</label>

          <br />
          <div className="grid grid-cols-2 gap-4 mt-2">
            {sale.products.map((product) => (
              <>
                <label
                  className="relative grid cursor-pointer items-start justify-center border-2 border-[#ffffff]/50 rounded-[10px] "
                  key={product.name}
                >
                  <div className="absolute w-[130px] flex items-center justify-end mr-2 h-[20px] mt-[5px]">
                    <span className="flex items-center justify-center bg-[#00DD80] rounded-[27px] w-[35px] py-[4px] text-white text-[12px] leading-[14px]">
                      ${product.price}
                    </span>
                  </div>
                  <div className="">
                    <img
                      src={product.image.url}
                      alt={product.name}
                      className="rounded-t-[8px]"
                    />
                  </div>
                  <div className="flex items-center justify-center py-2">
                    <input
                      id={product.name}
                      type="checkbox"
                      {...register("want_to_buy", {
                        required: "At least select one",
                      })}
                      value={product.id}
                      className="mr-2 rounded-sm border-none cursor-pointer"
                    />
                    <label for={product.name}>{product.name}</label>
                  </div>
                </label>
              </>
            ))}
          </div>
          {errors.want_to_buy && (
            <div className="font-['Montserrat'] flex items-center justify-end text-[12px] leading-[15px]">
              <div className="bg-[#FF3D5F] py-[5px] px-[20px] rounded-l-full rounded-br-full mt-1 mr-1">
                {errors.want_to_buy.message}
              </div>
            </div>
          )}
          <br />
          <label>Phone Number</label>
          <p className="text-[12px] leading-[14.63px] text-[#B4B4B4] italic mb-2">
            (This is required for shipping purposes, please start with your
            country code)
          </p>
          <div className="phone">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: "phone is required",
                minLength: { value: 5, message: "phone is too short" },
              }}
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    country={userCountry}
                    onChange={(value, country) => {
                      setValue("phone", value);
                      if (country.countryCode !== "id") {
                        setInternationalBuyer(true);
                        // setinternationalAddress(false);
                        setUserCountry(country.countryCode);
                        setValue("shipping_address", "paypal");
                      } else {
                        setIndonesiaBuyer(false);
                        setUserCountry(country.countryCode);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
          {errors.phone && (
            <div className="font-['Montserrat'] flex items-center justify-end text-[12px] leading-[15px]">
              <div className="bg-[#FF3D5F] py-[5px] px-[20px] rounded-l-full rounded-br-full mt-1 mr-2">
                {errors.phone.message}
              </div>
            </div>
          )}
          <br />
          {internationalBuyer && (
            <div>
              <label>Shipping Address</label>
              <br />
              <div className="flex items-center mt-2">
                <input
                  {...register("shipping_address", {
                    required: "Must select shipping option",
                  })}
                  type="radio"
                  value="paypal"
                  id="paypal"
                  className="w-[18px] h-[18px] mr-1"
                  onClick={() => setinternationalAddress(false)}
                />
                <label for="paypal">Same with paypal</label>
              </div>

              <div className="flex items-center mt-2">
                <input
                  {...register("shipping_address", {
                    required: "Must select shipping option",
                  })}
                  type="radio"
                  value="other"
                  id="other"
                  className="w-[18px] h-[18px] mr-1"
                  onClick={() => setinternationalAddress(true)}
                />
                <label for="other">Other address</label>
              </div>
              {internationalAddress && (
                <div className="flex items-center mt-2 whitespace-normal w-[290px] h-[120px]">
                  <textarea
                    {...register("shipping_address", {
                      required: "Must select shipping option",
                    })}
                    rows="5"
                    cols="60"
                    className="bg-white/10 p-[13px] whitespace-normal focus:outline-none rounded-[10px] border-[1px] border-white w-[290px] h-[120px] my-3 "
                  />
                </div>
              )}
              <br />
              {errors.shipping_address && errors.shipping_address.message}
            </div>
          )}
          <label>Suggestion</label>
          <br />
          <input
            {...register("notes")}
            className="bg-white/10 px-[13px] focus:outline-none rounded-[10px] border-[1px] border-white w-[290px] h-[40px] my-3"
          />
          <br />
          <button className="bg-white rounded-[12px] border-[1px] border-white w-[290px] h-[50px] my-3 text-[#265CA6] text-[14px] leading-[17px] font-bold cursor-pointer">
            SUBMIT FORM
          </button>
        </form>
        {showModal && <OrderPlacedModal onClose={() => setShowModal(false)} />}
        {errorModal && (
          <ErrorModal
            data={<>User already registered</>}
            onClose={() => setErrorModal(false)}
          />
        )}
      </div>
      <GoToTop />
    </>
  );
};

export default Form;
